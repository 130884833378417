import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerSecondary from "../../components/banners/bannerSecondary"
import Why from "../../components/why"
import Benefits from "../../components/benefits"
import Reviews from "../../components/reviews"
import Treatments from "../../components/treatments"
import CallToAction from "../../components/callToAction"
import FeaturedFaq from "../../components/faqs/featuredFaq"
import {
  Section,
  Container,
  Flex,
  FlexMobileOpp,
} from "../../components/layoutComponents"
import { StaticImage } from "gatsby-plugin-image"
import Calander from "../../components/calander"

export default function ReikiCalgary() {
  return (
    <Layout>
      <SEO
        title="Reiki Practicioner near Calgary and Distance Healing Sessions"
        description=""
      />
      <BannerSecondary
        title="reiki & distance healing sessions"
        description="Helping you address and acknowledge areas of your body that are giving you issues to find a resolution for long-lasting or permanent healing."
        to1="/"
        link1="home"
        to2="/treatments"
        link2="treatments"
        to3="/treatments/reiki-calgary"
        link3="reiki calgary"
      />
      <Section>
        <Container className="spacing">
          <h1 className="title bold caps">reiki calgary - what is it?</h1>
          <Flex>
            <div className="spacing">
              <p>
                Simply put, our reiki calgary services is a healing technique
                that involves our therapists chanelling energy into our patients
                by a means of touch. Similar to massage, this process activates
                the natural healing process within one’s body, mind, and soul to
                restore both physical and emotional well-being. There is a vital
                form of energy that flows through your body. As your Calgary
                reiki practitioner, it is our responsibility to use a gentle
                touch, or to place our hands just above your body, to guide this
                energy throughout your body in a way that promotes healing.
                During your Calgary reiki healing session, you will lay flat on
                our reiki massage table to allow us to place our hands over your
                body in order to transfer our energy to you, and guide it to the
                right places to encourage the natural healing process.
              </p>
              <p>
                Reiki works for some people, and it doesn’t work for others. It
                is up to you to have an open mind about it so your body and mind
                can naturally accept the energy being transferred to you by your
                reiki practitioners in Calgary. After your reiki session, you
                will likely feel relaxed and tired, so it is important that you
                book a session on a day that your schedule can be cleared.
              </p>
              <div>
                <p>
                  We are located just South of Calgary, and to many small towns
                  including:
                </p>
                <ul>
                  <li>Foothills</li>
                  <li>Diamond Valley</li>
                  <li>Millarville</li>
                  <li>Black Diamond</li>
                  <li>Turney Valley</li>
                  <li>Okotokes</li>
                  <li>Bragg Creek</li>
                  <li>Aldersyde</li>
                  <li>and more!</li>
                </ul>
              </div>
            </div>
            <StaticImage
              src="../../images/reiki-calgary-healing-session.jpg"
              alt="reiki calgary"
              className="stretch"
            />
          </Flex>
        </Container>
      </Section>
      <Section>
        <Container className="spacing">
          <h2 className="title bold caps">distance healing sessions</h2>
          <FlexMobileOpp>
            <StaticImage
              src="../../images/distance-healing-and-reiki.jpg"
              alt="calgary reiki and distance healing sessions"
              className="stretch"
            />
            <div>
              <p>
                Our Calgary distance healing sessions are for those that would
                rather experience the benefits of reiki from the comfort of
                their home. Our distance healing sessions are also great for
                those that haven’t done reiki before, and would like to be
                introduced to it in a more comfortable environment. Distance
                healing is completely virtual, meaning we will meet over the
                phone, or over Zoom, and we will ground you to mother earth
                while sharing our energy with you through the sound and
                vibrations of our calm and grounded voice. You will sit with
                your eyes closed, feet planted to the ground, and we will slowly
                take you to the area of your body that needs attention. We talk
                about how it feels and where it possibly came from, whether it’s
                chronic pain, an old injury, or even feelings of anxiety. This
                process is very simple and we are only addressing the area of
                your body that is causing you problems, and we acknowledge it
                during your calgary distance healing session - that’s it! Just
                like reiki, the ability to acknowledge these issues throughout
                your body and mind promote the natural healing process.
                Acknowledging the pain and finding a story behind it is what
                allows you to naturally find a resolution. Just like reiki, this
                virtual healing practice works for some, and it doesn’t work for
                others, but it is up to you to be open minded about it.{" "}
              </p>
            </div>
          </FlexMobileOpp>
        </Container>
      </Section>
      <Reviews />
      <CallToAction />
      <Why />
      <Benefits />
      <FeaturedFaq />
      <Treatments />
      <CallToAction />
      <Calander />
    </Layout>
  )
}
